import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PopoverController, ModalController, IonContent } from '@ionic/angular';
import { ProfileService } from 'src/app/services/user/profile.service';

@Component({
  selector: 'app-start-new',
  templateUrl: './start-new.page.html',
  styleUrls: ['./start-new.page.scss'],
})
export class StartNewPage implements OnInit {
  new_number: string;

  userProfile: any;
  allowedNumbers: any;
  private expanded: string;
  private collapsed: string;
  newMessage: string;
  selectedNumber: string;


  @ViewChild('content') content: IonContent;

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private profileService: ProfileService,
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.profileService.getUserProfile().then(user => {
      this.userProfile = user;
      this.allowedNumbers = this.userProfile.messagesApp.allowedNumbers;
    })
  }
  closeModal()
  {
    console.log('modal dismissing now..');
    this.modalCtrl.dismiss({
      messageText: this.newMessage,
      from: this.selectedNumber,
      to: this.new_number 
    });
  }

  cancelSend() {
    this.modalCtrl.dismiss({
      cancel: true
    });
  }
  

  onBlur() {
    //console.log('onBlur triggered.')
    // Keeps track of the expanded state of the text area.
    let expanded = this.element.nativeElement.querySelector('textarea').style.height;
    if (expanded != this.collapsed) {
      this.expanded = expanded;
    }
    /*
    if (!this.message) {
      // Collapsed the expanded text area since the message is cleared.
      let element = this.element.nativeElement.querySelector('textarea');
      element.style.height = this.collapsed;
      this.collapsed = null;
      this.expanded = null;
    }
    */
  }

  onFocus() {
    //console.log('onFocus triggered');
    // Expand the text area depending on the length of the message.
    // If the text area is expanded when it lost focus, it will retain the expanded state when focused.
    console.log('messagebox focus: ',this.element.nativeElement.querySelector('textarea'));
    let element = this.element.nativeElement.querySelector('textarea');
    if (this.expanded) {
      element.style.height = this.expanded;
    } else {
      if (!this.collapsed) {
        this.collapsed = this.element.nativeElement.querySelector('textarea').style.height;
      }
      element.style.height = this.collapsed;
    }

    //this.scrollDown();
    const textArea = this.element.nativeElement.querySelector('textarea');
    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  keyDownFunction(event) {
    //User pressed return on the keyboard, send the text message.
    
    const textArea = this.element.nativeElement.querySelector('textarea');
    //console.log('Got textarea: ',textArea);
    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

}
