import { Injectable } from '@angular/core';
import { ProfileService } from '../user/profile.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrdersService {

  constructor(
    private profileService: ProfileService,
    private afs: AngularFirestore
  ) { }

  getServiceOrdersCollectionByAccount(aid: string):AngularFirestoreCollection<{}> {
    return this.afs.collection(`/serviceOrders`, ref => ref.orderBy('serviceOrderDueAt', 'asc')
    .where('allowed_accounts', 'array-contains',aid)
    .where('orderClosed', '==',false));
  }

  getServiceOrdersEventsCollection(orderId: string):AngularFirestoreCollection<{}>{
    return this.afs.collection(`/serviceOrders/${orderId}/events`, ref=>ref.orderBy('createdAt','desc'));
  }
  getServiceOrdersEventsLastMessage(orderId: string):AngularFirestoreCollection<{}>{
    return this.afs.collection(`/serviceOrders/${orderId}/events`,ref=>ref.orderBy('createdAt','desc').limit(1));
  }
  getServiceOrdersDocument(docId: string):Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      console.log('request to load: ',docId);
      resolve(this.afs.doc(`/serviceOrders/${docId}`));
    })
  }
}


