import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore'

import { ProfileService } from '../user/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {

  public userProfile: any;

  constructor(
    private profileService: ProfileService,
    private afs: AngularFirestore
  ) { 
    
  }

  
  
  getAccountConversations(aid: string): AngularFirestoreCollection<{}> {
    //console.log('conversationService aid: ',aid);
    //console.log(`service will return collection: accounts/${aid}/conversations`);
    return this.afs.collection(`accounts/${aid}/conversations`, ref =>ref.orderBy('lastMessageDate', 'desc'));
  }

  public getCRMContactByNumber(aid: string, number: string){
    // return this.afs.collection(`account/${aid}/contacts`, ref => ref.startAt(number).endAt(number+'\uf8ff'));
    return this.afs.collection(`accounts/${aid}/contacts`, ref => ref.where('mobile_phone', '==',number));
  }

  getConversation(aid: string, conversationId: string): Promise<AngularFirestoreDocument<{}>> {
    //console.log(`ervice will return conversation path: accounts/${aid}/conversations/${conversationId}`);
    return new Promise(resolve => {
      resolve(this.afs.doc(`accounts/${aid}/conversations/${conversationId}`));
    })
  }

  public getConversationMessages(aid: string, conversationId: string): AngularFirestoreCollection<{}> {
    return this.afs.collection(`accounts/${aid}/conversations/${conversationId}/messages`, ref => ref.orderBy('time'));
  }

  getConversationMessage(aid: string, conversationId: string, messageId: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(`accounts/${aid}/conversations/${conversationId}/messages/${messageId}`));
    })
  }

  findConversationByParties(aid: string, localNumber: string, receipients: string) {
    console.log(`Service will search for aid: ${aid} and receipient: ${receipients}`);
    return this.afs.collection(`accounts/${aid}/conversations`, 
    ref => ref.where('localNumber', '==', localNumber).where('receipients', 'array-contains', receipients))
  }
}
